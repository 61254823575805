import React from 'react';

import classNames from 'classnames';
import { snakeToPascal } from '../../utils/caseconverters';
import dynamic from 'next/dynamic';

const SocialProof = dynamic(() => import('../SocialProof'));

const Components = {
    SocialProof,
};

const GlobalModules = ({ block = [] }) => {
    if (!block.length) {
        return null;
    }

    const dynamicComponents = block.map((item, index) => {
        item.component = snakeToPascal(item.component);
        const Component = Components[item.component];

        if (!Component) {
            return null;
        }

        return <Component key={index} id={item.id} {...item} />;
    });

    return <>{dynamicComponents}</>;
};

GlobalModules.propTypes = {};

export default GlobalModules;
